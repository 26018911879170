import Vue from "vue";
import { apiOpsBff, memory, memoryPrivate, usersBff } from "ngt-frontend-core";
import dayjs from "dayjs";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import { i18n } from "@/locales/i18n";
import PortalVue from "portal-vue";
import "@/mixins/commonMixins";
// import '@/filters/commonDataFilters'

// tmp - remove traces of old login
memory.remove("user");

Vue.use(PortalVue);

var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

Vue.prototype.$EventBus = new Vue();

Vue.config.productionTip = false;

async function main() {
  apiOpsBff.connectWithStore(store, i18n);
  usersBff.initializeLogin(router, store);

  const user = await memoryPrivate.getItemAsync("user");
  store.commit("user", user || false);

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount("#app");
}

if (process.env.TYPE === undefined || process.env.TYPE === "browser") {
  main();
} else {
  document.addEventListener("deviceready", main);
}
