import Vue from "vue";

Vue.mixin({
  methods: {
    getDefaultLocale() {
      let locale = localStorage.getItem("locale");
      if (locale == "null") locale = null;
      if (!locale && this.$store && this.$store.state.user) {
        locale = this.$store.state.user.locale;
      }
      if (!locale) {
        locale = "en_US";
      }
      return locale;
    },

    getDefaultTimeZone() {
      let timezone = localStorage.getItem("timezone");
      if (timezone == "null") timezone = null;
      const user = this.$store.state.user;
      if (!timezone && this.$store && this.$store.state.user) {
        timezone = this.$store.state.user.timezone;
      }
      if (!timezone) {
        timezone = "GMT";
      }
      return timezone;
    }
  }
});
