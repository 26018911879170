import Vue from "vue";
import Vuex from "vuex";
import { mapMutations, memory, memoryPrivate } from "ngt-frontend-core";
import { getField, updateField } from "vuex-map-fields";
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

// set global variable to keep stream
let messageStream = null;

// try to get saved application settings from localStorage or set a default one
const appSettings = {};
Object.assign(appSettings, {
  gdprCookie: false
});
memory.getItem(process.env.VUE_APP_SETTINGS_STORE_VARIABLE, item => {
  Object.assign(appSettings, item);
});

Vue.use(Vuex);

const state = {
  loading: false,
  user: false,
  tags: false,
  hide_toolbar: false,
  open_settings_dialog: false,
  canInstallPWA: false,
  page: "",
  drawer: {
    open: false,
    clipped: true,
    mobileBreakPoint: 15000,
    fixed: false,
    permanent: false,
    mini: true
  },
  APIdata: {}, // for API response cache
  appSettings,
  /*** IMPORTANT BASE CONFIG ***/
  // API URL for identities handling
  idp_api_url: process.env.VUE_APP_IDP_API,
  device_api_url: process.env.VUE_APP_DEVICE_API,
  bff_api_url: process.env.VUE_APP_API_URL,
  idp_reg_url: process.env.VUE_APP_IDP_REGISTRATION,
  idp_pwd_url: process.env.VUE_APP_IDP_PASSWORD_RESET,
  idp_loginMan_url: process.env.VUE_APP_IDP_LOGIN_MANAGER,
  /*** END OF IMPORTANT BASE CONFIG ***/
  /* custom states */
  selectedAsset: {
    objectId: null,
    assetDetails: {},
    datasources: [],
    messages: [],
    lastSensorStates: [],
    lastAssetState: {},
    graphData: []
  },
  streamDate: {
    from: "",
    to: ""
  },
  graphChannels: null
  /* end of custom states */
};

export default new Vuex.Store({
  state,
  mutations: {
    updateField,
    ...mapMutations(state),
    device(state, setVal) {
      state.device = { ...state.device, ...setVal };
    },
    drawer(state, setVal) {
      state.drawer = { ...state.drawer, ...setVal };
    },
    setAppSetting(state, valueObject) {
      if (Object.prototype.hasOwnProperty.call(valueObject, "parent")) {
        state.appSettings[valueObject.parent][valueObject.key] =
          valueObject.value;
      } else {
        state.appSettings[valueObject.key] = valueObject.value;
      }
      memory.setItem(
        process.env.VUE_APP_SETTINGS_STORE_VARIABLE,
        state.appSettings
      );
    },
    setAsset(state, asset) {
      state.selectedAsset.objectId = asset.assetDetails.objectid;
      state.selectedAsset.assetDetails = asset.assetDetails;
      state.selectedAsset.datasources = asset.datasources;
      state.selectedAsset.messages = asset.messages;
    },
    insertAssetMessage(state, message) {
      if (message) {
        state.selectedAsset.messages.push(JSON.parse(message));
      }
    },
    setLastSensorState(state, sensorStates) {
      state.selectedAsset.lastSensorStates = sensorStates;
    },
    setLastAssetState(state, assetState) {
      state.selectedAsset.lastAssetState = assetState;
    },
    setStreamDate(state, dates) {
      state.streamDate = dates;
    },
    setGraphChannels(state, channels) {
      state.graphChannels = channels;
    },
    insertGraphData(state, { timestamp, sensordatas }) {
      const graphData = {
        date: timestamp,
        messages: []
      };
      // have we got value or any relevant record for all sensors?
      state.selectedAsset.datasources.forEach(datasource => {
        const foundSensorData = sensordatas.find(
          item => item.datasourceid === datasource.datasourceid
        );
        let sensorValue = 0;
        if (foundSensorData == null || foundSensorData.value == null) {
          if (state.selectedAsset.graphData.length) {
            sensorValue =
              state.selectedAsset.graphData[
                state.selectedAsset.graphData.length - 1
              ];
          }
        } else {
          sensorValue = foundSensorData.value;
        }
        graphData.messages.push({
          datasourceId: datasource.datasourceid,
          value: sensorValue
        });
      });
      state.selectedAsset.graphData.push(graphData);
    }
  },
  actions: {
    resetAssetDetails({ commit }) {
      commit("loading", true);
      callAPI({
        url: `${
          state.device_api_url
        }/mf-devices?objectcode=${encodeURIComponent(
          this.state.selectedAsset.assetDetails.objectcode
        )}`,
        method: "GET"
      })
        .then(({ data }) => {
          this.state.selectedAsset.assetDetails = data[0];
        })
        .catch(() => {})
        .finally(() => {
          commit("loading", false);
        });
    },
    async setAssetDetails({ commit, dispatch }, assetDetails) {
      commit("loading", true);
      const result = await callAPI({
        url: `${state.device_api_url}/mf-devices/${assetDetails.objectid}/datasources`,
        method: "GET",
        cache: true
      });
      if (result) {
        commit("setAsset", {
          assetDetails,
          datasources: result.data,
          messages: []
        });
        commit("loading", false);
        // start the stream
        const filter = state.streamDate.from
          ? state.streamDate.to
            ? `?filter=interval:gte:${state.streamDate.from},interval:lte:${state.streamDate.to}`
            : `?filter=interval:gte:${state.streamDate.from}`
          : "";
        const streamUrl = `${state.device_api_url}/mf-devices/${state.selectedAsset.objectId}/messages${filter}`;
        const cid = await memoryPrivate.getItemAsync("cid");
        const codeVerifier = await memoryPrivate.getItemAsync("codeVerifier");
        callAPI({
          url: `${
            state.device_api_url
          }/_service/cookie?authToken=${encodeURIComponent(
            `${cid}|${codeVerifier}`
          )}&authScheme=bff_token`,
          method: "GET",
          withCredentials: true
        })
          .then(() => {
            dispatch("closeMessageStream");
            messageStream = new EventSource(streamUrl, {
              withCredentials: true
            });
            messageStream.onmessage = function(event) {
              // save to messages
              commit("insertAssetMessage", event.data);
              // save to actual asset data, graph data and last sensor states
              const parsedData = JSON.parse(event.data);
              if (Array.isArray(parsedData.parsed)) {
                parsedData.parsed.forEach(item => {
                  if (item.sensordatas.length) {
                    commit("insertGraphData", {
                      timestamp: item.timestamp,
                      sensordatas: item.sensordatas
                    });
                    commit("setLastSensorState", item.sensordatas);
                  }
                  delete item.sensordatas;
                  commit("setLastAssetState", item);
                });
              }
            };
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    resetMessageStream({ dispatch }) {
      dispatch("closeMessageStream");
      dispatch("setAssetDetails", state.selectedAsset.assetDetails);
    },
    closeMessageStream() {
      if (messageStream) {
        messageStream.close();
        messageStream = null;
      }
      // set nullables
      // state.selectedAsset.objectId = null;
      // state.selectedAsset.datasources = [];
      // state.selectedAsset.messages = [];
      state.selectedAsset.lastSensorStates = [];
      state.selectedAsset.lastAssetState = {};
      state.selectedAsset.graphData = [];
    },
    resetMap() {
      state.graphChannels = null;
    }
  },
  getters: {
    getField,
    getTags: state => () => state.tags,
    datasourceIds: state => () =>
      state.selectedAsset.datasources.map(item => item.datasourceid),
    datasource: state => datasourceId =>
      state.selectedAsset.datasources.find(
        item => item.datasourceid === datasourceId
      ),
    sensorData: state => datasourceId =>
      state.selectedAsset.lastSensorStates.find(
        item => item.datasourceid === datasourceId
      )
  },
  modules: {}
});
