<template>
  <AppBase v-if="authenticated" :items="items" />
  <Authentication v-else />
</template>

<script>
import { LoginBff as Authentication } from "ngt-frontend-core";
import { AppBase, memory, usersBff } from "ngt-frontend-core";
import dayjs from "dayjs";
const { apiOpsBff } = require("ngt-frontend-core");

export default {
  name: "App",
  components: {
    AppBase,
    Authentication
  },
  data: () => {
    return {
      existingTags: [],
      items: [],
      timers: {},
      timerId: undefined
    };
  },
  created() {
    if (this.authenticated) {
      // set global timer handling
      this.$EventBus.$on("timerAttach", (callbackEvent, interval = 0) => {
        this.timers[callbackEvent] = this.timers[callbackEvent] || {};
        this.timers[callbackEvent].interval = interval;
        this.timers[callbackEvent].called = new Date().getTime();
      });
      this.timerId = window.requestAnimationFrame(this.executeTimedEvents);
    }

    this.$EventBus.$on("buildMenuItems", () => {
      this.buildMenuItems();
      this.$store.commit("page", this.$route.meta.title());
    });

    this.init();
  },
  beforeDestroy() {
    window.cancelAnimationFrame(this.timerId);
    this.timerId = undefined;
    this.$EventBus.$off();
  },
  computed: {
    authenticated() {
      return this.$store.state.user;
    }
  },
  watch: {
    $route(to, from) {
      // re-update local settings to match with user default locale
      const locale = this.getDefaultLocale();
      const short_locale = locale.substring(0, 2);
      // console.log("App.vue - SET LOCALE: ", locale, short_locale)

      this.$i18n.locale = short_locale;
      dayjs.locale(short_locale);

      const timezone = this.getDefaultTimeZone();
      // console.log("App.vue - SET TIMEZONE: ", timezone)
      dayjs.tz.setDefault(timezone);

      // refresh available menu points
      this.buildMenuItems();

      if (typeof to.meta.title === "function") {
        this.$store.commit("page", to.meta.title());
      }
    }
  },
  methods: {
    async init() {
      // detect default language
      const browserLanguage = usersBff.getBrowserLanguage();
      const storedLocale = await memory.getItemAsync("locale");
      const locale = storedLocale || browserLanguage || "en";
      // i18n.locale = locale;

      await apiOpsBff.setI18nLanguage(locale);
      this.$EventBus.$emit("buildMenuItems");

      dayjs.locale(
        locale && locale.length === 2
          ? `${locale}_${locale.toUpperCase()}`
          : locale
      );
    },
    executeTimedEvents() {
      const currTime = new Date().getTime();
      for (const item in this.timers) {
        if (currTime - this.timers[item].called > this.timers[item].interval) {
          delete this.timers[item];
          this.$EventBus.$emit(item);
        }
      }
      this.timerId = window.requestAnimationFrame(this.executeTimedEvents);
    },
    buildMenuItems() {
      const noLicense = [
        {
          title: this.$t("no_license"),
          action: "NoLicense"
        }
      ];
      const standard = [
        {
          title: this.$t("home"),
          action: "Home"
        },
        {
          title: this.$t("asset_details"),
          action: "Asset Details"
        },
        {
          title: this.$t("asset_stream"),
          action: "Asset Sensor Stream"
        }
      ];
      const admin = [];
      if (this.authenticated && this.authenticated.ownedBy) {
        // TODO: handling rights and menu access in the future
        this.items = standard.concat(admin);
      } else {
        this.items = noLicense;
      }
    }
  }
};
</script>

<style lang="scss">
$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

main {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px !important;
}
.main-view {
  position: relative;
  height: auto;
}
.v-menu__content {
  background-color: $white;
}

/* drawer menu bottom logos */
.v-navigation-drawer__append {
  .v-list {
    .v-list-item {
      .container {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        padding-left: 0 !important;
        padding-right: 0 !important;

        .row {
          flex-direction: column;
          .col {
            max-width: 90%;
            padding-bottom: 0 !important;
          }
          .col:nth-child(1) {
            .v-image__image {
              background-image: url("assets/text-logo.png") !important;
              background-size: contain;
            }
          }
          .col:nth-child(2) {
            .v-responsive__sizer {
              padding-bottom: 20px !important;
            }
            .v-image__image {
              background-size: contain;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.scrollLimit {
  max-height: 300px;
  overflow-y: scroll;
}
.dataSelector {
  // max-width: 300px;
  width: 96%;
  margin: 0 auto;

  .topTitle {
    border-bottom: 1px solid #afafaf;
  }

  .v-list-item__subtitle {
    max-width: 274px;
  }

  .bottom-search-for-selector {
    border-top: 1px solid #afafaf;

    label,
    .v-icon {
      opacity: 0.5;
    }
  }

  .top-search-for-selector {
    border-bottom: 1px solid #afafaf;

    label,
    .v-icon {
      opacity: 0.5;
    }
  }
}

.v-application {
  .pa-0 {
    padding: 0 !important;
  }
}
.pa-0 {
  padding: 0 !important;
}
.more-space {
  white-space: normal !important;
  max-height: 116px !important;
}
.dataSelector {
  .v-list-item {
    .v-list-item__title {
      color: rgba(0, 0, 0, 1) !important;
    }
  }
}
.fa-square::before {
  color: rgba(0, 0, 0, 0.6) !important;
}
.fa-check-square::before {
  color: #0090d4 !important;
}
</style>
