import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/locales/i18n";
import { LoginBff } from "ngt-frontend-core";
import { NoLicense } from "ngt-frontend-core";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    /* common routes */
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/Home"),
      meta: {
        title: () => i18n.t("home")
      }
    },
    {
      path: "/",
      name: "Asset Details",
      component: () => import("@/views/AssetDetails"),
      meta: {
        title: () => i18n.t("asset_details")
      }
    },
    {
      path: "/",
      name: "Asset Sensor Stream",
      component: () => import("@/views/AssetStream"),
      meta: {
        title: () => i18n.t("asset_stream")
      }
    },
    /*service routes */
    {
      path: "/authentication",
      name: "Login Page",
      component: LoginBff,
      meta: {
        title: () => i18n.t("login")
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: () => import("@/views/Settings"),
      meta: {
        title: () => i18n.t("title_settings")
      }
    },
    {
      path: "/noLicense",
      name: "NoLicense",
      component: NoLicense,
      meta: {
        title: () => i18n.t("no_license")
      }
    }
  ]
});

// Helper function to prevent duplicate navigation error
router.push_safe = params => {
  let from = router.currentRoute.fullPath;
  let to = router.resolve(params).route.fullPath;
  if (from === to) return;

  router.push(params);
};

export default router;
